.focus {
    transition: transform 0.3s;
  }
  
  .focus:hover {
    transform: scale(1.05);
  }
  
  .card-title {
    font-size: 2em;
    font-weight: 700;
  }
  .card-deck{
    display: flex;
    justify-content: center;
    flex-flow: nowrap;
    margin: 0;
    margin-bottom: 10px;
  }
.card-deck .card{
  width: 95%;
}

  @media only screen and (max-width: 786px){
    .card-deck{
      /* flex-flow: column;
      justify-content: center; */
      margin: 0 90px 0 90px;
      flex-direction: column;
    }
  }
  
  .image-style {
    width: 3em;
  }
  
  .font-details {
    font-size: 3em;
    font-weight: 500;
    color: #231d5af8;
  }
  
  .font-details-b {
    font-size: 3em;
    font-weight: 500;
    color: #ffffff;
  }