.logo {
      width: 54%;
      margin-left: 34px;
  }
  
  .nav-theme {
    background-color: rgba(16,16,24,.89);
    font-size: 20px;
    height: 85px !important;
  }
  .navbar-dark .navbar-nav .nav-link{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .animate-navbar {
    box-shadow: 1px 1px 1px #222;
    animation: moveDown 0.5s ease-in-out;
  }
  @media only screen and (max-width: 786px){
    .nav-theme{
      height: auto !important;
      width: 100%;
    }
    .logo{
    margin-bottom:-32px;
    margin-left: 30px;
    margin-top: -32px
    }
  }
  
  @keyframes moveDown {
    from {
      transform: translateY(-5rem);
    }
    to {
      transform: translateY(0rem);
    }
  }